import axios from "axios";
import { API_URL } from "../api/config";
import { autoLogout } from "./logout";
import { getCookie } from "./cookie";

export async function getPaginate(url, page = 1, limit = 10, params = "") {
  const res = axios
    .get(`${url}?page=${page}&perPage=${limit}&${params}`, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("_biskon_session")).accessToken
        }`,
        Accept: "application/json",
        "Content-Type": "application/json;",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else if (error?.response?.status === 403) {
        window.location.href = "/403";
      } else {
        discordWebhook(url + " " + error?.response);
        return error?.response;
      }
    });

  return res;
}

export async function get(url, params = "") {
  const res = axios
    .get(url + params, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("_biskon_session")).accessToken
        }`,
        Accept: "application/json",
        "Content-Type": "application/json;",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        autoLogout();
      } else if (error?.response?.status === 403) {
        window.location.href = "/403";
      } else {
        discordWebhook(url + " " + error.response);

        return error.response;
      }
    });

  return res;
}

export async function post(url, params = "", value) {
  const res = await axios
    .post(url + params, value, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("_biskon_session")).accessToken
        }`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        autoLogout();
      } else if (error?.response?.status === 403) {
        window.location.href = "/403";
      } else {
        discordWebhook(url + " " + error.response);

        return error.response;
      }
    });

  return res;
}

export async function put(url, params = "", value) {
  const res = axios
    .put(url + params, value, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("_biskon_session")).accessToken
        }`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        autoLogout();
      } else if (error?.response?.status === 403) {
        window.location.href = "/403";
      } else {
        discordWebhook(url + " " + error.response);

        return error.response;
      }
    });

  return res;
}

export async function patch(url, params = "", value) {
  const res = axios
    .patch(url + params, value, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("_biskon_session")).accessToken
        }`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        autoLogout();
      } else if (error?.response?.status === 403) {
        window.location.href = "/403";
      } else {
        discordWebhook(url + " " + error.response);

        return error.response;
      }
    });

  return res;
}

export async function del(url, params = "") {
  const res = axios
    .delete(url + params, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("_biskon_session")).accessToken
        }`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        autoLogout();
      } else if (error?.response?.status === 403) {
        window.location.href = "/403";
      } else {
        discordWebhook(url + " " + error.response);

        return error.response;
      }
    });

  return res;
}

export async function discordWebhook(message) {
  // const DISCORD_WEBHOOK =
  //   "https://discord.com/api/webhooks/1174286758079385641/cb93VtGCL3W_6COPgbDDpiUnyzDRTzoT7BUnVmw4LCGOuITimKTafssAWbNZ006NcJJa";
  // const value = { content: message };
  // const res = await axios
  //   .post(DISCORD_WEBHOOK, JSON.stringify(value), {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json;",
  //     },
  //   })
  //   .then((res) => {
  //     return res;
  //   })
  //   .catch((error) => {
  //     return error.response;
  //   });
  // return res;
}
