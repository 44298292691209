import React from "react";

import { Col, Row, Button } from "antd";

export default function PageTitle(props) {
  const {
    pageTitle,
    pageText,
    leftButton,
    rightButton,
    mobileRightButton,
    mobileTheme = "default",
    mobileOnlyLeftButton = false,
    mobileOnlyRightButton = false,
    children,
  } = props;

  var bgTheme = "";
  var textTheme = "";
  if (mobileTheme === "primary") {
    bgTheme = "hp-bg-color-primary-1";
    textTheme = "hp-text-color-black-0";
  } else {
    bgTheme = "hp-bg-color-black-0 hp-bg-color-dark-90";
    textTheme = "hp-text-color-black-90 hp-text-color-dark-10";
  }

  if (leftButton && rightButton) {
    textTheme += " hp-text-center";
  }

  return (
    <>
      <Row className="hp-mb-24 hp-page-title" align="center">
        {leftButton && mobileOnlyLeftButton === false && (
          <Col>{leftButton}</Col>
        )}
        {pageTitle && (
          <Col flex="auto">
            <h2 className="hp-mb-0">{pageTitle}</h2>
            {pageText && <p className="hp-mb-0 hp-p1-body">{pageText}</p>}
          </Col>
        )}
        {children && (
          <Col flex="auto" align="right">
            <Row span={24} justify="end">
              {children}
            </Row>
          </Col>
        )}
        {rightButton && mobileOnlyRightButton === false && (
          <>
            <Col>{rightButton}</Col>
          </>
        )}
      </Row>

      <Row className={"hp-topbar " + bgTheme} align={"center"}>
        {leftButton && (
          <Col flex={"none"} className={textTheme}>
            {leftButton}
          </Col>
        )}
        {pageTitle && (
          <Col flex={"auto"}>
            <h4 className={"hp-topbar-title " + textTheme}>{pageTitle}</h4>
          </Col>
        )}
        {rightButton && mobileOnlyRightButton === false && (
          <>
            {mobileRightButton ? (
              <Col>{mobileRightButton}</Col>
            ) : (
              <Col>{rightButton}</Col>
            )}
          </>
        )}
      </Row>
    </>
  );
}
