import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card } from "antd";
import PageTitle from "../../../layout/components/content/page-title";
import themeConfig from "../../../configs/themeConfig";
import { Link } from "react-router-dom";
import {
  RiArrowLeftLine,
  RiHeadphoneLine,
  RiPhoneLine,
  RiUserLine,
} from "react-icons/ri";
import BottomMenu from "../../../layout/components/bottom-menu";
import {
  CloseSquare,
  Danger,
  Document,
  Paper,
  TickSquare,
  TimeCircle,
} from "react-iconly";
import Overview from "./_overview";
import Reschedule from "./_reschedule";
import { get } from "../../../utils/service";
import { API_URL } from "../../../api/config";

export default function Pages() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState({});

  const getData = async () => {
    setIsLoading(true);
    const res = await get(API_URL.dashboard + "/getpanggilan");
    if (res?.status === 200) {
      setDataSource(res?.data?.data || {});
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getData();
    document.title = "Dashboard - " + themeConfig.appname;
  }, []);

  return (
    <>
      <PageTitle pageTitle="Dashboard" />
      <div className="hp-content-main">
        <Row gutter={[16, 16]}>
          <Col span={24} lg={16}>
            <Row gutter={[16, 16]}>
              <Col span={24} lg={12}>
                <Card className="hp-dashboard-card">
                  <div className="hp-d-flex hp-align-items-center">
                    <div className="hp-bg-warning-4 hp-bg-dark-warning hp-text-color-warning-1 icon">
                      <Paper size={24} />
                    </div>
                    <div>
                      <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                        Pending
                      </p>
                      <h4 className="hp-mb-0">{dataSource?.pending ?? 0}</h4>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={24} lg={12}>
                <Card className="hp-dashboard-card">
                  <div className="hp-d-flex hp-align-items-center">
                    <div className="hp-bg-orange-4 hp-bg-dark-orange hp-text-color-orange-1 icon">
                      <TimeCircle size={24} />
                    </div>
                    <div>
                      <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                        Reject
                      </p>
                      <h4 className="hp-mb-0">{dataSource?.reject ?? 0}</h4>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={24} lg={12}>
                <Card className="hp-dashboard-card">
                  <div className="hp-d-flex hp-align-items-center">
                    <div className="hp-bg-success-4 hp-bg-dark-success hp-text-color-success-1 icon">
                      <TickSquare size={24} />
                    </div>
                    <div>
                      <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                        Correct
                      </p>
                      <h4 className="hp-mb-0">{dataSource?.correct ?? 0}</h4>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={24} lg={12}>
                <Card className="hp-dashboard-card">
                  <div className="hp-d-flex hp-align-items-center">
                    <div className="hp-bg-danger-4 hp-bg-dark-danger hp-text-color-danger-1 icon">
                      <Danger size={24} />
                    </div>
                    <div>
                      <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                        Indikasi
                      </p>
                      <h4 className="hp-mb-0">{dataSource?.indikasi ?? 0}</h4>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={24} lg={12}>
                <Card className="hp-dashboard-card">
                  <div className="hp-d-flex hp-align-items-center">
                    <div className="hp-bg-grey-4 hp-bg-dark-grey hp-text-color-grey-1 icon">
                      <CloseSquare size={24} />
                    </div>
                    <div>
                      <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                        False
                      </p>
                      <h4 className="hp-mb-0">{dataSource?.false ?? 0}</h4>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={24} lg={8}>
            <Overview dataSource={dataSource} />
          </Col>
        </Row>
      </div>
      <BottomMenu active="home" />
    </>
  );
}
