import { Link } from "react-router-dom";

import { Dropdown, Col, Avatar, Divider, Row, Modal } from "antd";
import { Call, Logout, User } from "react-iconly";

import avatarImg from "../../../assets/images/default-profile.svg";
import themeConfig from "../../../configs/themeConfig";
import { autoLogout } from "../../../utils/logout";

const { confirm } = Modal;

export default function HeaderUser() {
  const storage = localStorage.getItem("_biskon_session");
  const profile = JSON.parse(storage);

  const handleLogout = () => {
    confirm({
      title: (
        <h5 className="hp-mb-0 hp-font-weight-500">
          Apakah Anda yakin akan Logout?
        </h5>
      ),
      icon: false,
      onOk: () => {
        autoLogout();
      },
    });
  };

  const menu = (
    <div
      className="hp-border-radius hp-border-1 hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-p-24 hp-mt-12"
      style={{ width: 260 }}
    >
      <Row>
        <Col span={24}>
          <Link
            to="/profile/my-profile"
            className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius hp-mb-8"
            style={{ marginLeft: -10, marginRight: -10 }}
          >
            <User set="curved" size={16} />

            <span className="hp-ml-8">Profil Saya</span>
          </Link>
        </Col>

        <Col span={24}>
          <a
            href={"https://wa.me/" + themeConfig.whatsapp}
            className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius "
            style={{ marginTop: -7, marginLeft: -10, marginRight: -10 }}
          >
            <Call set="curved" size={16} />

            <span className="hp-ml-8">Hubungi Admin</span>
          </a>
        </Col>
      </Row>

      <Divider className="hp-mb-16 hp-mt-6" />

      <Row>
        <Col span={24}>
          <a
            onClick={() => {
              handleLogout();
            }}
            className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition hp-text-color-danger-1 hp-hover-bg-danger-4 hp-hover-bg-dark-80 hp-border-radius"
            style={{ marginLeft: -10, marginRight: -10 }}
          >
            <Logout set="curved" size={16} />

            <span className="hp-ml-8 ">Logout</span>
          </a>
        </Col>
      </Row>
    </div>
  );

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Avatar
          src={
            profile?.photo !== null && profile?.photo !== ""
              ? profile?.photo
              : avatarImg
          }
          size={40}
          className="hp-cursor-pointer"
        />
      </Dropdown>
    </Col>
  );
}
