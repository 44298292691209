import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "antd";
import Chart from "react-apexcharts";

export default function Overview(props) {
  const dataPending = props.dataSource?.pending ?? 0;
  const dataCorrect = props.dataSource?.correct ?? 0;
  const dataIndikasi = props.dataSource?.indikasi ?? 0;
  const dataReject = props.dataSource?.reject ?? 0;
  const dataFalse = props.dataSource?.false ?? 0;

  const series = [
    dataPending,
    dataCorrect,
    dataIndikasi,
    dataReject,
    dataFalse,
  ];

  const [chartData, setChartData] = useState({
    series: series,
    options: {
      chart: {
        fontFamily: "Manrope, sans-serif",
        type: "donut",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#ffc212", "#00b000", "#ff0022", "#ff7512", "#8485a7"],
      labels: ["Pending", "Correct", "Indikasi", "Reject", "False"],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "85%",
            labels: {
              show: true,
              name: {
                fontSize: "2rem",
              },
              value: {
                fontSize: "16px",
              },
              total: {
                show: true,
                fontSize: "16px",
                label: "Total",
              },
            },
          },
        },
      },
      legend: {
        itemMargin: {
          horizontal: 24,
          vertical: 0,
        },
        horizontalAlign: "center",
        position: "bottom",
        fontSize: "14px",
        markers: {
          radius: 12,
        },
      },
    },
  });

  useEffect(() => {
    setChartData((prevState) => ({
      ...prevState,
      series: series,
    }));
  }, [dataPending, dataCorrect, dataIndikasi, dataReject, dataFalse]);

  return (
    <Card className="hp-mb-24">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row justify="space-between" align="top">
            <Col>
              <h4 className="hp-mr-8">Ringkasan Panggilan</h4>
              <p className="hp-badge-text">Persentase status panggilan</p>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <div id="chart" className="hp-donut-chart">
            {props.dataSource && (
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="donut"
                height={398}
                legend="legend"
              />
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
}
