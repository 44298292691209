import {
  Calendar,
  Call,
  Category,
  Document,
  Folder,
  Paper,
  Password,
  People,
  TimeCircle,
  User,
} from "react-iconly";

const pages = [
  {
    header: "UTAMA",
    access: null,
  },
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Category set="curved" className="remix-icon" />,
    navLink: "/dashboard",
    access: null,
  },
  {
    id: "submission",
    title: "Tarik Data",
    icon: <Paper set="curved" className="remix-icon" />,
    navLink: "/tarik-data",
    access: null,
  },
  {
    id: "call",
    title: "Panggilan",
    icon: <Call set="curved" className="remix-icon" />,
    access: ["panggilan-index"],
    children: [
      {
        id: "pending-call",
        title: "Pending",
        navLink: "/call/pending",
        access: ["panggilan-index"],
      },
      {
        id: "correct-call",
        title: "Correct",
        navLink: "/call/correct",
        access: ["panggilan-index"],
      },
      {
        id: "indikasi-call",
        title: "Indikasi",
        navLink: "/call/indikasi",
        access: ["panggilan-index"],
      },
      {
        id: "reject-call",
        title: "Reject",
        navLink: "/call/reject",
        access: ["panggilan-index"],
      },
      {
        id: "false-call",
        title: "False",
        navLink: "/call/false",
        access: ["panggilan-index"],
      },
    ],
  },
  {
    header: "LAINNYA",
    access: null,
  },
  {
    id: "user",
    title: "Manajemen User",
    icon: <People set="curved" className="remix-icon" />,
    navLink: "/user/data",
    access: ["user-index"],
  },
  {
    id: "master-data",
    title: "Master Data",
    icon: <Folder set="curved" className="remix-icon" />,
    access: ["regional-index", "area-index", "cabang-index"],
    children: [
      {
        id: "regional",
        title: "Regional",
        navLink: "/regional/data",
        access: ["regional-index"],
      },
      {
        id: "area",
        title: "Area",
        navLink: "/area/data",
        access: ["area-index"],
      },
      {
        id: "cabang",
        title: "Cabang",
        navLink: "/cabang/data",
        access: ["cabang-index"],
      },
    ],
  },
  {
    id: "role",
    title: "Role & Permission",
    icon: <Password set="curved" className="remix-icon" />,
    navLink: "/role/data",
    access: ["role-index"],
  },
  {
    id: "profile",
    title: "Profile",
    icon: <User set="curved" className="remix-icon" />,
    navLink: "/profile/my-profile",
    access: null,
  },
];

export default pages;
